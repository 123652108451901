import React, {
  forwardRef,
  LegacyRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IconProps, useApiProps } from "../icon";
// import "../style/css";

const WechatErrorIcon = forwardRef<SVGSVGElement, IconProps>(
  (props: IconProps, ref: LegacyRef<SVGSVGElement>) => {
    const {
      colors = [],
      className,
      id = "",
      hover = "",
      size,
      disabled = false,
      style,
      color,
      onMouseEnter,
      onMouseLeave,
      ...resultProps
    } = props;
    //@ts-ignore
    const { sizeStyle, cls } = useApiProps({
      className,
      initColor: ["black","#D54941","white"],
      initName: "wechat-error",
      colors,
      id,
      hover,
      size,
      style,
      disabled,
      color,
    });
    // 默认的color 列表
    const [colorList, setColorList] = useState(["black","#D54941","white"]);
    const [colorsProps, setColorsProps] = useState(["black","#D54941","white"]);
    const [defaultColor, _] = useState(["black","#D54941","white"]);

    useEffect(() => {
      if (typeof colors === "string" && "wechat-error".indexOf("-colored") === -1) {
        const col = JSON.parse(JSON.stringify(["black","#D54941","white"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = colors;
            }
            return item;
          });
        } else {
          _col[0] = colors;
        }
        setColorList(_col);
        setColorsProps(_col);
      }

      // 如果colors 是数组
      if (colors instanceof Array && "wechat-error".indexOf("-colored") === -1) {
        // 获取数组中的对象
        const colorList1 = JSON.parse(JSON.stringify(["black","#D54941","white"]));
        colors.forEach((col) => {
          // 获取到修改的元素
          const findColorIndex = colorList1.findIndex(
            (v) => v === col.targetColor
          );
          if (findColorIndex >= 0) {
            colorList1[findColorIndex] = col.reviseColor;
          }
        });
        setColorList([...colorList1]);
        setColorsProps([...colorList1]);
      }

      if (
        color &&
        typeof color === "string" &&
        "wechat-error".indexOf("-colored") === -1
      ) {
        const col = JSON.parse(JSON.stringify(["black","#D54941","white"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = color;
            }
            return item;
          });
        } else {
          _col[0] = color;
        }
        setColorList(_col);
        setColorsProps(_col);
      }
    }, [JSON.stringify(colors), color, defaultColor]);

    const handleMouseEnter = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseEnter && onMouseEnter(event);
        if (!hover || hover.length === 0) return;
        if (colors instanceof Array && "wechat-error".indexOf("-colored") === -1) {
          if (hover instanceof Array && "wechat-error".indexOf("-colored") === -1) {
            // 获取数组中的对象
            const colorList1 = JSON.parse(JSON.stringify(colorList));
            hover.forEach((col) => {
              // 获取到修改的元素
              const findColorIndex = colorList1.findIndex(
                (v) => v === col.targetColor
              );
              if (findColorIndex >= 0) {
                colorList1[findColorIndex] = col.reviseColor;
              }
            });
            setColorList([...colorList1]);
          }
        }

        if (typeof colors === "string" && "wechat-error".indexOf("-colored") === -1) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === colors);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === colors) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          color &&
          typeof color === "string" &&
          "wechat-error".indexOf("-colored") === -1
        ) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === color);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === color) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          !color &&
          colors.length === 0 &&
          typeof hover === "string" &&
          "wechat-error".indexOf("-colored") === -1
        ) {
          const col = JSON.parse(JSON.stringify(colorList));
          const find = col.find((item) => item === "currentColor");
          let _col = col;
          if (find) {
            _col = col.map((item) => {
              if (item === "currentColor") {
                item = hover;
              }
              return item;
            });
          } else {
            _col[0] = hover;
          }
          setColorList(_col);
        }

        if (
          !color &&
          colors.length === 0 &&
          hover instanceof Array &&
          "wechat-error".indexOf("-colored") === -1
        ) {
          // 获取数组中的对象
          const colorList1 = JSON.parse(JSON.stringify(colorList));
          hover.forEach((col) => {
            // 获取到修改的元素
            const findColorIndex = colorList1.findIndex(
              (v) => v === col.targetColor
            );
            if (findColorIndex >= 0) {
              colorList1[findColorIndex] = col.reviseColor;
            }
          });
          setColorList([...colorList1]);
        }
      },
      [colorList, defaultColor, colors, color, hover]
    );

    const handleMouseLeave = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseLeave && onMouseLeave(event);
        if (!hover || hover.length === 0) return;
        let _defaultColor = JSON.parse(JSON.stringify(defaultColor));
        if (colorsProps) {
          _defaultColor = JSON.parse(JSON.stringify(colorsProps));
        }
        setColorList(_defaultColor);
      },
      [colorsProps, defaultColor, hover]
    );

    return <svg id="wechat-error"  style={sizeStyle}  className={cls}  ref={ref}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...resultProps}  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="none"><path d="M0.825684 7.78439C0.825684 4.49519 3.90832 2 7.48248 2C10.8936 2 13.8547 4.27109 14.12 7.33755C13.6866 7.09586 13.2037 6.93241 12.6903 6.8663C12.1907 4.90621 10.1222 3.33333 7.48248 3.33333C4.44013 3.33333 2.15902 5.42071 2.15902 7.78439C2.15902 9.08961 2.83639 10.2906 3.96657 11.1239L4.31033 11.3773L4.06907 12.5434L5.41021 11.8908L5.6609 11.9674C6.22773 12.1404 6.84091 12.2355 7.48248 12.2355C7.79678 12.2355 8.10428 12.2127 8.40284 12.169C8.56291 12.621 8.80192 13.0356 9.10328 13.3963C8.58364 13.5091 8.04059 13.5688 7.48248 13.5688C6.80395 13.5688 6.14767 13.4806 5.52866 13.316L3.31321 14.3941H2.32461L2.83485 11.9279C1.61184 10.8917 0.825684 9.42953 0.825684 7.78439Z" fill={colorList[0]} fillOpacity="0.9"/><path d="M5.37375 5.52441C4.90992 5.52441 4.53369 5.90044 4.53369 6.36448C4.53369 6.82865 4.9099 7.2047 5.37375 7.2047C5.83793 7.2047 6.21398 6.82865 6.21398 6.36448C6.21398 5.90044 5.83791 5.52441 5.37375 5.52441Z" fill={colorList[0]} fillOpacity="0.9"/><path d="M9.57471 5.52441C9.11055 5.52441 8.73449 5.90044 8.73449 6.36448C8.73449 6.82865 9.11053 7.2047 9.57471 7.2047C10.0387 7.2047 10.4149 6.82867 10.4149 6.36448C10.4149 5.90042 10.0387 5.52441 9.57471 5.52441Z" fill={colorList[0]} fillOpacity="0.9"/><path d="M9.17432 10.8334C9.17432 9.17652 10.5175 7.83337 12.1743 7.83337C13.8312 7.83337 15.1743 9.17652 15.1743 10.8334C15.1743 12.4902 13.8312 13.8334 12.1743 13.8334C10.5175 13.8334 9.17432 12.4902 9.17432 10.8334Z" fill={colorList[1]} fillRule="evenodd" clipRule="evenodd"/><path d="M12.6176 11.3309L12.6176 8.88867L11.731 8.88867L11.731 11.3309L12.6176 11.3309Z" fill={colorList[2]}/><path d="M12.6212 11.8887H11.731V12.7788H12.6212V11.8887Z" fill={colorList[2]}/></g></svg>;
  }
);

WechatErrorIcon.displayName = "WechatErrorIcon";

export default WechatErrorIcon;
