// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M2.57 3h18.86l-6.93 9.82V21h-5v-8.18L2.57 3zm3.86 2l5.07 7.18V19h1v-6.82L17.57 5H6.43z"}}]};

const FilterIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'filter',
    ref,
    icon: element,
  },
));

FilterIcon.displayName = 'FilterIcon';

export default FilterIcon;
