import {
  CSSProperties,
  SVGAttributes,
  ForwardRefExoticComponent,
  RefAttributes,
  useState,
  useEffect,
  useContext,
} from "react";
import classNames from "classnames";
import useSizeProps from "./util/use-size-props";
import { IconConfigContext } from './configProvider';

export interface IconColor {
  targetColor: string;
  reviseColor: string;
}

export interface IconProps extends SVGAttributes<SVGSVGElement> {
  style?: CSSProperties;
  className?: string;
  size?: number | string;
  /**
   * hover color修改: Colored 无效
   */
  hover?: string | IconColor[];

  /**
   * hover 背景色修改:Colored 无效
   */
  // hoverBackground?:string;

  /**
   * 修改icon颜色: Colored 无效
   */
  colors?: string | IconColor[];
  /**
   * 修改icon颜色: Colored 无效 单色图标有用，多色图标无法使用
   */
  color?: string;
  /**
   * icon禁用
   */
  disabled?: boolean;
  /**
   * icon禁用
   */
  onClick?: (e?: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

export interface IconFulfilledProps extends IconProps {
  initColor: string[];
  initName: string;
}

export type CompoundedComponent = ForwardRefExoticComponent<
  IconFulfilledProps & RefAttributes<SVGElement>
>;

// 高阶组件
export const useApiProps = (
  props: IconFulfilledProps
): {
  colorList: string[];
  sizeStyle: CSSProperties;
  cls: any;
  setColors: React.Dispatch<React.SetStateAction<string[]>>;
} => {
  const {
    colors,
    color,
    className,
    hover,
    size,
    disabled,
    initColor,
    initName,
    style,
  } = props;

  const [_color, setColors] = useState<string[]>(initColor);
  const defalutColor = initColor;
  const { className: sizeClassName, style: _sizeStyle } = useSizeProps(size);
  const { classPrefix } = useContext(IconConfigContext);

  const sizeStyle = {
    ..._sizeStyle,
    ...style,
  };

  const cls = classNames(
    `${classPrefix}-icon`,
    className,
    `${classPrefix}-icon-${initName}`,
    sizeClassName,
    `${hover !== "" ? classPrefix + "-cursor" : ""}`,
    `${disabled ? classPrefix + "-disabled" : ""}`
  );

  useEffect(() => {
    if (typeof colors === "string" && initName.indexOf("-colored") === -1) {
      const col = [...defalutColor];
      col[0] = colors;
      setColors(col);
    }

    // 如果colors 是数组
    if (colors instanceof Array && initName.indexOf("-colored") === -1) {
      // 获取数组中的对象
      const colorList = [...defalutColor];
      colors.forEach((col) => {
        // 获取到修改的元素
        const findColorIndex = colorList.findIndex(
          (v) => v === col.targetColor
        );
        if (findColorIndex >= 0) {
          colorList[findColorIndex] = col.reviseColor;
        }
      });
      setColors([...colorList]);
    }

    if (
      color &&
      typeof color === "string" &&
      initName.indexOf("-colored") === -1
    ) {
      const col = [...defalutColor];
      col[0] = color;
      setColors(col);
    }
  }, [JSON.stringify(colors), color]);

  return {
    colorList: _color,
    sizeStyle,
    cls,
    setColors,
  };
};
