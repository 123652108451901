// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M12 23a11 11 0 100-22 11 11 0 000 22zM7.5 10.59l3 3 6-6L17.91 9l-7.41 7.41L6.09 12l1.41-1.41z"}}]};

const CheckCircleFilledIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'check-circle-filled',
    ref,
    icon: element,
  },
));

CheckCircleFilledIcon.displayName = 'CheckCircleFilledIcon';

export default CheckCircleFilledIcon;
