// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M12 23a11 11 0 110-22 11 11 0 010 22zm1-17.5h-2v6.91l4 4L16.41 15 13 11.59V5.5z"}}]};

const TimeFilledIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'time-filled',
    ref,
    icon: element,
  },
));

TimeFilledIcon.displayName = 'TimeFilledIcon';

export default TimeFilledIcon;
