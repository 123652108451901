// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M7.05 5.64L12 10.59l4.95-4.95 1.41 1.41L13.41 12l4.95 4.95-1.41 1.41L12 13.41l-4.95 4.95-1.41-1.41L10.59 12 5.64 7.05l1.41-1.41z"}}]};

const CloseIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'close',
    ref,
    icon: element,
  },
));

CloseIcon.displayName = 'CloseIcon';

export default CloseIcon;
