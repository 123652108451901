// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M17.5 5v14h-2V5h2zm-9.75.34L14.41 12l-6.66 6.66-1.41-1.41L11.59 12 6.34 6.75l1.41-1.41z"}}]};

const PageLastIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'page-last',
    ref,
    icon: element,
  },
));

PageLastIcon.displayName = 'PageLastIcon';

export default PageLastIcon;
