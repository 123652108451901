// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M12 3a9 9 0 100 18 9 9 0 000-18zM1 12a11 11 0 1122 0 11 11 0 01-22 0zm9.5-5.41L15.91 12l-5.41 5.41L9.09 16l4-4-4-4 1.41-1.41z"}}]};

const ChevronRightCircleIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'chevron-right-circle',
    ref,
    icon: element,
  },
));

ChevronRightCircleIcon.displayName = 'ChevronRightCircleIcon';

export default ChevronRightCircleIcon;
