// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M12 .85l9.66 5.57v11.16L12 23.15l-9.66-5.57V6.42L12 .85zm0 2.3L4.34 7.58v8.84L12 20.85l7.66-4.43V7.58L12 3.15zM12 9a3 3 0 100 6 3 3 0 000-6zm-5 3a5 5 0 1110 0 5 5 0 01-10 0z"}}]};

const SettingIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'setting',
    ref,
    icon: element,
  },
));

SettingIcon.displayName = 'SettingIcon';

export default SettingIcon;
