import React, {
  forwardRef,
  LegacyRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IconProps, useApiProps } from "../icon";
// import "../style/css";

const UserConfirmedIcon = forwardRef<SVGSVGElement, IconProps>(
  (props: IconProps, ref: LegacyRef<SVGSVGElement>) => {
    const {
      colors = [],
      className,
      id = "",
      hover = "",
      size,
      disabled = false,
      style,
      color,
      onMouseEnter,
      onMouseLeave,
      ...resultProps
    } = props;
    //@ts-ignore
    const { sizeStyle, cls } = useApiProps({
      className,
      initColor: ["black","#00A870","white"],
      initName: "user-confirmed",
      colors,
      id,
      hover,
      size,
      style,
      disabled,
      color,
    });
    // 默认的color 列表
    const [colorList, setColorList] = useState(["black","#00A870","white"]);
    const [colorsProps, setColorsProps] = useState(["black","#00A870","white"]);
    const [defaultColor, _] = useState(["black","#00A870","white"]);

    useEffect(() => {
      if (typeof colors === "string" && "user-confirmed".indexOf("-colored") === -1) {
        const col = JSON.parse(JSON.stringify(["black","#00A870","white"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = colors;
            }
            return item;
          });
        } else {
          _col[0] = colors;
        }
        setColorList(_col);
        setColorsProps(_col);
      }

      // 如果colors 是数组
      if (colors instanceof Array && "user-confirmed".indexOf("-colored") === -1) {
        // 获取数组中的对象
        const colorList1 = JSON.parse(JSON.stringify(["black","#00A870","white"]));
        colors.forEach((col) => {
          // 获取到修改的元素
          const findColorIndex = colorList1.findIndex(
            (v) => v === col.targetColor
          );
          if (findColorIndex >= 0) {
            colorList1[findColorIndex] = col.reviseColor;
          }
        });
        setColorList([...colorList1]);
        setColorsProps([...colorList1]);
      }

      if (
        color &&
        typeof color === "string" &&
        "user-confirmed".indexOf("-colored") === -1
      ) {
        const col = JSON.parse(JSON.stringify(["black","#00A870","white"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = color;
            }
            return item;
          });
        } else {
          _col[0] = color;
        }
        setColorList(_col);
        setColorsProps(_col);
      }
    }, [JSON.stringify(colors), color, defaultColor]);

    const handleMouseEnter = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseEnter && onMouseEnter(event);
        if (!hover || hover.length === 0) return;
        if (colors instanceof Array && "user-confirmed".indexOf("-colored") === -1) {
          if (hover instanceof Array && "user-confirmed".indexOf("-colored") === -1) {
            // 获取数组中的对象
            const colorList1 = JSON.parse(JSON.stringify(colorList));
            hover.forEach((col) => {
              // 获取到修改的元素
              const findColorIndex = colorList1.findIndex(
                (v) => v === col.targetColor
              );
              if (findColorIndex >= 0) {
                colorList1[findColorIndex] = col.reviseColor;
              }
            });
            setColorList([...colorList1]);
          }
        }

        if (typeof colors === "string" && "user-confirmed".indexOf("-colored") === -1) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === colors);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === colors) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          color &&
          typeof color === "string" &&
          "user-confirmed".indexOf("-colored") === -1
        ) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === color);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === color) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          !color &&
          colors.length === 0 &&
          typeof hover === "string" &&
          "user-confirmed".indexOf("-colored") === -1
        ) {
          const col = JSON.parse(JSON.stringify(colorList));
          const find = col.find((item) => item === "currentColor");
          let _col = col;
          if (find) {
            _col = col.map((item) => {
              if (item === "currentColor") {
                item = hover;
              }
              return item;
            });
          } else {
            _col[0] = hover;
          }
          setColorList(_col);
        }

        if (
          !color &&
          colors.length === 0 &&
          hover instanceof Array &&
          "user-confirmed".indexOf("-colored") === -1
        ) {
          // 获取数组中的对象
          const colorList1 = JSON.parse(JSON.stringify(colorList));
          hover.forEach((col) => {
            // 获取到修改的元素
            const findColorIndex = colorList1.findIndex(
              (v) => v === col.targetColor
            );
            if (findColorIndex >= 0) {
              colorList1[findColorIndex] = col.reviseColor;
            }
          });
          setColorList([...colorList1]);
        }
      },
      [colorList, defaultColor, colors, color, hover]
    );

    const handleMouseLeave = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseLeave && onMouseLeave(event);
        if (!hover || hover.length === 0) return;
        let _defaultColor = JSON.parse(JSON.stringify(defaultColor));
        if (colorsProps) {
          _defaultColor = JSON.parse(JSON.stringify(colorsProps));
        }
        setColorList(_defaultColor);
      },
      [colorsProps, defaultColor, hover]
    );

    return <svg id="user-confirmed"  style={sizeStyle}  className={cls}  ref={ref}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...resultProps}  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="none"><path d="M5.66667 4.66671C5.66667 3.56214 6.5621 2.66671 7.66667 2.66671C8.77124 2.66671 9.66667 3.56214 9.66667 4.66671C9.66667 5.77128 8.77124 6.66671 7.66667 6.66671C6.5621 6.66671 5.66667 5.77128 5.66667 4.66671ZM7.66667 1.33337C5.82572 1.33337 4.33333 2.82576 4.33333 4.66671C4.33333 6.50766 5.82572 8.00004 7.66667 8.00004C9.50762 8.00004 11 6.50766 11 4.66671C11 2.82576 9.50762 1.33337 7.66667 1.33337ZM5.33333 9.33337C3.49238 9.33337 2 10.8258 2 12.6667V13.3334L2 13.6667H3.33333V13.3334V12.6667C3.33333 11.5621 4.22876 10.6667 5.33333 10.6667H8.45935C8.58454 10.1803 8.79893 9.7297 9.08403 9.33337H5.33333Z" fill={colorList[0]} fillRule="evenodd" clipRule="evenodd" fillOpacity="0.9"/><path d="M9.3335 11.6667C9.3335 10.0098 10.6766 8.66669 12.3335 8.66669C13.9904 8.66669 15.3335 10.0098 15.3335 11.6667C15.3335 13.3235 13.9904 14.6667 12.3335 14.6667C10.6766 14.6667 9.3335 13.3235 9.3335 11.6667Z" fill={colorList[1]} fillRule="evenodd" clipRule="evenodd"/><path d="M14.1273 11L12.0003 13.127L10.54 11.6667L11.167 11.0397L12.0003 11.873L13.5003 10.373L14.1273 11Z" fill={colorList[2]} fillRule="evenodd" clipRule="evenodd"/></g></svg>;
  }
);

UserConfirmedIcon.displayName = "UserConfirmedIcon";

export default UserConfirmedIcon;
