// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M5.59 16.5l4.5-4.5-4.5-4.5L7 6.09 12.91 12 7 17.91 5.59 16.5zm6.5 0l4.5-4.5-4.5-4.5 1.41-1.41L19.41 12l-5.91 5.91-1.41-1.41z"}}]};

const ChevronRightDoubleIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'chevron-right-double',
    ref,
    icon: element,
  },
));

ChevronRightDoubleIcon.displayName = 'ChevronRightDoubleIcon';

export default ChevronRightDoubleIcon;
