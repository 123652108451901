// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M4 2v9a9 9 0 019 9h9v2H2V2h2zm0 18h7a7 7 0 00-7-7v7z"}}]};

const RotationIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'rotation',
    ref,
    icon: element,
  },
));

RotationIcon.displayName = 'RotationIcon';

export default RotationIcon;
