// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M2 2h20v20H2V2zm2 2v16h16V4H4zm4 2.37L17.75 12 8 17.63V6.37zm2 3.46v4.34L13.75 12 10 9.83z"}}]};

const VideoIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'video',
    ref,
    icon: element,
  },
));

VideoIcon.displayName = 'VideoIcon';

export default VideoIcon;
