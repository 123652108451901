// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M15 3.09l7.41 7.41H2v-2h15.59l-4-4L15 3.09zm7 10.41v2H6.41l4 4L9 20.91 1.59 13.5H22z"}}]};

const SwapIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'swap',
    ref,
    icon: element,
  },
));

SwapIcon.displayName = 'SwapIcon';

export default SwapIcon;
