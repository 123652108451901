// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M12 2.59l5.91 5.91-1.41 1.41-3.5-3.5V16h-2V6.41l-3.5 3.5L6.09 8.5 12 2.59zM4.5 14v5h15v-5h2v7h-19v-7h2z"}}]};

const UploadIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'upload',
    ref,
    icon: element,
  },
));

UploadIcon.displayName = 'UploadIcon';

export default UploadIcon;
