// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M2 2h20v20H2V2zm2 2v16h16V4H4zm2.5 7h11v2h-11v-2z"}}]};

const MinusRectangleIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'minus-rectangle',
    ref,
    icon: element,
  },
));

MinusRectangleIcon.displayName = 'MinusRectangleIcon';

export default MinusRectangleIcon;
