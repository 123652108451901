import React, {
  forwardRef,
  LegacyRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IconProps, useApiProps } from "../icon";
// import "../style/css";

const GuideRectangleIcon = forwardRef<SVGSVGElement, IconProps>(
  (props: IconProps, ref: LegacyRef<SVGSVGElement>) => {
    const {
      colors = [],
      className,
      id = "",
      hover = "",
      size,
      disabled = false,
      style,
      color,
      onMouseEnter,
      onMouseLeave,
      ...resultProps
    } = props;
    //@ts-ignore
    const { sizeStyle, cls } = useApiProps({
      className,
      initColor: ["currentColor"],
      initName: "guide-rectangle",
      colors,
      id,
      hover,
      size,
      style,
      disabled,
      color,
    });
    // 默认的color 列表
    const [colorList, setColorList] = useState(["currentColor"]);
    const [colorsProps, setColorsProps] = useState(["currentColor"]);
    const [defaultColor, _] = useState(["currentColor"]);

    useEffect(() => {
      if (typeof colors === "string" && "guide-rectangle".indexOf("-colored") === -1) {
        const col = JSON.parse(JSON.stringify(["currentColor"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = colors;
            }
            return item;
          });
        } else {
          _col[0] = colors;
        }
        setColorList(_col);
        setColorsProps(_col);
      }

      // 如果colors 是数组
      if (colors instanceof Array && "guide-rectangle".indexOf("-colored") === -1) {
        // 获取数组中的对象
        const colorList1 = JSON.parse(JSON.stringify(["currentColor"]));
        colors.forEach((col) => {
          // 获取到修改的元素
          const findColorIndex = colorList1.findIndex(
            (v) => v === col.targetColor
          );
          if (findColorIndex >= 0) {
            colorList1[findColorIndex] = col.reviseColor;
          }
        });
        setColorList([...colorList1]);
        setColorsProps([...colorList1]);
      }

      if (
        color &&
        typeof color === "string" &&
        "guide-rectangle".indexOf("-colored") === -1
      ) {
        const col = JSON.parse(JSON.stringify(["currentColor"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = color;
            }
            return item;
          });
        } else {
          _col[0] = color;
        }
        setColorList(_col);
        setColorsProps(_col);
      }
    }, [JSON.stringify(colors), color, defaultColor]);

    const handleMouseEnter = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseEnter && onMouseEnter(event);
        if (!hover || hover.length === 0) return;
        if (colors instanceof Array && "guide-rectangle".indexOf("-colored") === -1) {
          if (hover instanceof Array && "guide-rectangle".indexOf("-colored") === -1) {
            // 获取数组中的对象
            const colorList1 = JSON.parse(JSON.stringify(colorList));
            hover.forEach((col) => {
              // 获取到修改的元素
              const findColorIndex = colorList1.findIndex(
                (v) => v === col.targetColor
              );
              if (findColorIndex >= 0) {
                colorList1[findColorIndex] = col.reviseColor;
              }
            });
            setColorList([...colorList1]);
          }
        }

        if (typeof colors === "string" && "guide-rectangle".indexOf("-colored") === -1) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === colors);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === colors) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          color &&
          typeof color === "string" &&
          "guide-rectangle".indexOf("-colored") === -1
        ) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === color);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === color) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          !color &&
          colors.length === 0 &&
          typeof hover === "string" &&
          "guide-rectangle".indexOf("-colored") === -1
        ) {
          const col = JSON.parse(JSON.stringify(colorList));
          const find = col.find((item) => item === "currentColor");
          let _col = col;
          if (find) {
            _col = col.map((item) => {
              if (item === "currentColor") {
                item = hover;
              }
              return item;
            });
          } else {
            _col[0] = hover;
          }
          setColorList(_col);
        }

        if (
          !color &&
          colors.length === 0 &&
          hover instanceof Array &&
          "guide-rectangle".indexOf("-colored") === -1
        ) {
          // 获取数组中的对象
          const colorList1 = JSON.parse(JSON.stringify(colorList));
          hover.forEach((col) => {
            // 获取到修改的元素
            const findColorIndex = colorList1.findIndex(
              (v) => v === col.targetColor
            );
            if (findColorIndex >= 0) {
              colorList1[findColorIndex] = col.reviseColor;
            }
          });
          setColorList([...colorList1]);
        }
      },
      [colorList, defaultColor, colors, color, hover]
    );

    const handleMouseLeave = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseLeave && onMouseLeave(event);
        if (!hover || hover.length === 0) return;
        let _defaultColor = JSON.parse(JSON.stringify(defaultColor));
        if (colorsProps) {
          _defaultColor = JSON.parse(JSON.stringify(colorsProps));
        }
        setColorList(_defaultColor);
      },
      [colorsProps, defaultColor, hover]
    );

    return <svg id="guide-rectangle"  style={sizeStyle}  className={cls}  ref={ref}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...resultProps}  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="none"><path d="M14.6665 1.33331L14.6665 14.6666L1.33317 14.6666L1.33317 1.33331L14.6665 1.33331ZM13.3332 2.66665L2.6665 2.66665L2.66651 13.3333L13.3332 13.3333L13.3332 2.66665Z" fill={colorList[0]} fillRule="evenodd" clipRule="evenodd" fillOpacity="0.9"/><path d="M8.66943 12.3359L7.3335 12.3359L7.3335 11L8.66943 11L8.66943 12.3359Z" fill={colorList[0]} fillRule="evenodd" clipRule="evenodd" fillOpacity="0.9"/><path d="M8.00016 4.99996C7.21222 4.99996 6.66683 5.57716 6.66683 6.17855V6.84521H5.3335V6.17855C5.3335 4.74172 6.57897 3.66663 8.00016 3.66663C9.42136 3.66663 10.6668 4.74172 10.6668 6.17855C10.6668 6.87817 10.3634 7.50303 9.89111 7.95025L9.87643 7.96416L8.66683 8.97777V9.99996H7.3335V8.35548L8.98743 6.96954C9.20919 6.75351 9.3335 6.47491 9.3335 6.17855C9.3335 5.57716 8.78811 4.99996 8.00016 4.99996Z" fill={colorList[0]} fillRule="evenodd" clipRule="evenodd" fillOpacity="0.9"/></g></svg>;
  }
);

GuideRectangleIcon.displayName = "GuideRectangleIcon";

export default GuideRectangleIcon;
