// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M3 1h12.41L21 6.59V23H3V1zm2 2v18h14V9h-6V3H5zm10 .41V7h3.59L15 3.41zM15 10v1.65a2 2 0 01-.61 1.44l-.95.91.95.91c.39.38.61.9.61 1.44V18h-2v-1.65l-1-.96-1 .96V18H9v-1.65a2 2 0 01.61-1.44l.95-.91-.95-.91A2 2 0 019 11.65V10h2v1.65l1 .96 1-.96V10h2z"}}]};

const FileExcelIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'file-excel',
    ref,
    icon: element,
  },
));

FileExcelIcon.displayName = 'FileExcelIcon';

export default FileExcelIcon;
