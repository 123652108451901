// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M3 1h12.41L21 6.59V23H3V1zm2 2v18h14V9h-6V3H5zm10 .41V7h3.59L15 3.41zM9 10h4a2 2 0 012 2v1.6a2 2 0 01-2 2h-2V18H9v-8zm2 3.6h2V12h-2v1.6z"}}]};

const FilePowerpointIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'file-powerpoint',
    ref,
    icon: element,
  },
));

FilePowerpointIcon.displayName = 'FilePowerpointIcon';

export default FilePowerpointIcon;
