import React, { useEffect, createContext, useState } from 'react';

type IconGlobalConfig = {
  /**
   * 组件类名前缀
   *
   * @default 'ruyi'
   */
  classPrefix: string;
};

type IconGlobalConfigProvider = React.FC<{
  globalConfig: IconGlobalConfig;
  children?: React.ReactNode
}>;

export const DEFAULT_CLASS_PREFIX = 'ruyi';

const defaultConfig: IconGlobalConfig = {
  classPrefix: DEFAULT_CLASS_PREFIX,
};

export const IconConfigContext = createContext<IconGlobalConfig>(defaultConfig);

export const IconConfigProvider: IconGlobalConfigProvider = ({
  children,
  globalConfig,
}) => {
  const [config, setConfig] = useState({ ...defaultConfig });

  useEffect(() => {
    setConfig({ ...defaultConfig, ...globalConfig });
  }, [globalConfig]);

  return (
    <IconConfigContext.Provider value={config}>
      {children}
    </IconConfigContext.Provider>
  );
};

