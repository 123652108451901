import React, {
  forwardRef,
  LegacyRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IconProps, useApiProps } from "../icon";
// import "../style/css";

const BrowseOffIcon = forwardRef<SVGSVGElement, IconProps>(
  (props: IconProps, ref: LegacyRef<SVGSVGElement>) => {
    const {
      colors = [],
      className,
      id = "",
      hover = "",
      size,
      disabled = false,
      style,
      color,
      onMouseEnter,
      onMouseLeave,
      ...resultProps
    } = props;
    //@ts-ignore
    const { sizeStyle, cls } = useApiProps({
      className,
      initColor: ["currentColor","white"],
      initName: "browse-off",
      colors,
      id,
      hover,
      size,
      style,
      disabled,
      color,
    });
    // 默认的color 列表
    const [colorList, setColorList] = useState(["currentColor","white"]);
    const [colorsProps, setColorsProps] = useState(["currentColor","white"]);
    const [defaultColor, _] = useState(["currentColor","white"]);

    useEffect(() => {
      if (typeof colors === "string" && "browse-off".indexOf("-colored") === -1) {
        const col = JSON.parse(JSON.stringify(["currentColor","white"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = colors;
            }
            return item;
          });
        } else {
          _col[0] = colors;
        }
        setColorList(_col);
        setColorsProps(_col);
      }

      // 如果colors 是数组
      if (colors instanceof Array && "browse-off".indexOf("-colored") === -1) {
        // 获取数组中的对象
        const colorList1 = JSON.parse(JSON.stringify(["currentColor","white"]));
        colors.forEach((col) => {
          // 获取到修改的元素
          const findColorIndex = colorList1.findIndex(
            (v) => v === col.targetColor
          );
          if (findColorIndex >= 0) {
            colorList1[findColorIndex] = col.reviseColor;
          }
        });
        setColorList([...colorList1]);
        setColorsProps([...colorList1]);
      }

      if (
        color &&
        typeof color === "string" &&
        "browse-off".indexOf("-colored") === -1
      ) {
        const col = JSON.parse(JSON.stringify(["currentColor","white"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = color;
            }
            return item;
          });
        } else {
          _col[0] = color;
        }
        setColorList(_col);
        setColorsProps(_col);
      }
    }, [JSON.stringify(colors), color, defaultColor]);

    const handleMouseEnter = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseEnter && onMouseEnter(event);
        if (!hover || hover.length === 0) return;
        if (colors instanceof Array && "browse-off".indexOf("-colored") === -1) {
          if (hover instanceof Array && "browse-off".indexOf("-colored") === -1) {
            // 获取数组中的对象
            const colorList1 = JSON.parse(JSON.stringify(colorList));
            hover.forEach((col) => {
              // 获取到修改的元素
              const findColorIndex = colorList1.findIndex(
                (v) => v === col.targetColor
              );
              if (findColorIndex >= 0) {
                colorList1[findColorIndex] = col.reviseColor;
              }
            });
            setColorList([...colorList1]);
          }
        }

        if (typeof colors === "string" && "browse-off".indexOf("-colored") === -1) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === colors);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === colors) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          color &&
          typeof color === "string" &&
          "browse-off".indexOf("-colored") === -1
        ) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === color);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === color) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          !color &&
          colors.length === 0 &&
          typeof hover === "string" &&
          "browse-off".indexOf("-colored") === -1
        ) {
          const col = JSON.parse(JSON.stringify(colorList));
          const find = col.find((item) => item === "currentColor");
          let _col = col;
          if (find) {
            _col = col.map((item) => {
              if (item === "currentColor") {
                item = hover;
              }
              return item;
            });
          } else {
            _col[0] = hover;
          }
          setColorList(_col);
        }

        if (
          !color &&
          colors.length === 0 &&
          hover instanceof Array &&
          "browse-off".indexOf("-colored") === -1
        ) {
          // 获取数组中的对象
          const colorList1 = JSON.parse(JSON.stringify(colorList));
          hover.forEach((col) => {
            // 获取到修改的元素
            const findColorIndex = colorList1.findIndex(
              (v) => v === col.targetColor
            );
            if (findColorIndex >= 0) {
              colorList1[findColorIndex] = col.reviseColor;
            }
          });
          setColorList([...colorList1]);
        }
      },
      [colorList, defaultColor, colors, color, hover]
    );

    const handleMouseLeave = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseLeave && onMouseLeave(event);
        if (!hover || hover.length === 0) return;
        let _defaultColor = JSON.parse(JSON.stringify(defaultColor));
        if (colorsProps) {
          _defaultColor = JSON.parse(JSON.stringify(colorsProps));
        }
        setColorList(_defaultColor);
      },
      [colorsProps, defaultColor, hover]
    );

    return <svg id="browse-off"  style={sizeStyle}  className={cls}  ref={ref}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...resultProps}  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="none"><g clipPath="url(#clip0_19498_1503)"><path d="M11.8062 12.7488L14.1955 15.1381L15.1383 14.1953L12.9678 12.0248L12.9685 12.0243L12.0136 11.0694L12.0129 11.0699L10.5401 9.59712L10.5406 9.59644L6.40368 5.45957L6.403 5.45999L5.19461 4.2516L5.19538 4.25127L4.19502 3.25091L4.1943 3.25129L1.80495 0.861938L0.862142 1.80475L3.03267 3.97528C1.74973 4.92939 0.750739 6.24366 0.18516 7.76806L0.0991211 7.99996L0.18516 8.23186C1.36217 11.4042 4.41622 13.6666 8.00037 13.6666C9.37172 13.6666 10.6655 13.3354 11.8062 12.7488ZM10.8058 11.7484L9.59732 10.5399C9.13484 10.8314 8.58719 11 8.00016 11C6.34331 11 5.00016 9.65681 5.00016 7.99996C5.00016 7.41293 5.16877 6.86528 5.46019 6.4028L3.98758 4.93019C2.90004 5.69229 2.03902 6.75585 1.52603 7.99996C2.57509 10.5441 5.07958 12.3333 8.00037 12.3333C8.99788 12.3333 9.94683 12.1246 10.8058 11.7484Z" fill={colorList[0]} fillRule="evenodd" clipRule="evenodd" fillOpacity="0.9"/><path d="M15.8156 8.23186C15.408 9.33036 14.7754 10.3198 13.9736 11.1439L13.0309 10.2011C13.6415 9.57026 14.1346 8.82479 14.4747 7.99996C13.4256 5.45579 10.9211 3.66663 8.00037 3.66663C7.53154 3.66663 7.07344 3.71272 6.63037 3.80064L5.53402 2.70429C6.31336 2.46316 7.14165 2.33329 8.00037 2.33329C11.5845 2.33329 14.6386 4.59568 15.8156 7.76806L15.9016 7.99996L15.8156 8.23186Z" fill={colorList[0]} fillOpacity="0.9"/><path d="M11.0002 7.99996C11.0002 8.05565 10.9986 8.11098 10.9956 8.16592L7.8342 5.00447C7.88914 5.00148 7.94448 4.99996 8.00016 4.99996C9.65702 4.99996 11.0002 6.3431 11.0002 7.99996Z" fill={colorList[0]} fillOpacity="0.9"/></g><defs><clipPath id="clip0_19498_1503"><rect width="16" height="16" fill={colorList[1]}/></clipPath></defs></g></svg>;
  }
);

BrowseOffIcon.displayName = "BrowseOffIcon";

export default BrowseOffIcon;
