// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M2 2h20v10h-2V4H4v9.59l5-5L14.41 14 13 15.41l-4-4-5 5V20h8v2H2V2zm13.55 5a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 116 0 3 3 0 01-6 0zm3.62 6.76L19 17.59l2.83-2.83 1.41 1.41L20.41 19l2.83 2.83-1.41 1.41L19 20.41l-2.83 2.83-1.41-1.41L17.59 19l-2.83-2.83 1.41-1.41z"}}]};

const ImageErrorIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'image-error',
    ref,
    icon: element,
  },
));

ImageErrorIcon.displayName = 'ImageErrorIcon';

export default ImageErrorIcon;
