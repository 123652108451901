// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M8.5 5v14h-2V5h2zm9.16 1.75L12.41 12l5.25 5.25-1.41 1.41L9.59 12l6.66-6.66 1.41 1.41z"}}]};

const PageFirstIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'page-first',
    ref,
    icon: element,
  },
));

PageFirstIcon.displayName = 'PageFirstIcon';

export default PageFirstIcon;
