// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M18.41 7.5l-4.5 4.5 4.5 4.5L17 17.91 11.09 12 17 6.09l1.41 1.41zm-6.5 0L7.41 12l4.5 4.5-1.41 1.41L4.59 12l5.91-5.91 1.41 1.41z"}}]};

const ChevronLeftDoubleIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'chevron-left-double',
    ref,
    icon: element,
  },
));

ChevronLeftDoubleIcon.displayName = 'ChevronLeftDoubleIcon';

export default ChevronLeftDoubleIcon;
