// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M13 2v20h-2V2h2zM9 4.64V18.5H1.3L9 4.64zm6 0l7.7 13.86H15V4.64zM4.7 16.5H7v-4.14L4.7 16.5zM17 12.36v4.14h2.3L17 12.36z"}}]};

const MirrorIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'mirror',
    ref,
    icon: element,
  },
));

MirrorIcon.displayName = 'MirrorIcon';

export default MirrorIcon;
