import React, {
  forwardRef,
  LegacyRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IconProps, useApiProps } from "../icon";
// import "../style/css";

const ChartPieIcon = forwardRef<SVGSVGElement, IconProps>(
  (props: IconProps, ref: LegacyRef<SVGSVGElement>) => {
    const {
      colors = [],
      className,
      id = "",
      hover = "",
      size,
      disabled = false,
      style,
      color,
      onMouseEnter,
      onMouseLeave,
      ...resultProps
    } = props;
    //@ts-ignore
    const { sizeStyle, cls } = useApiProps({
      className,
      initColor: ["currentColor","white"],
      initName: "chart-pie",
      colors,
      id,
      hover,
      size,
      style,
      disabled,
      color,
    });
    // 默认的color 列表
    const [colorList, setColorList] = useState(["currentColor","white"]);
    const [colorsProps, setColorsProps] = useState(["currentColor","white"]);
    const [defaultColor, _] = useState(["currentColor","white"]);

    useEffect(() => {
      if (typeof colors === "string" && "chart-pie".indexOf("-colored") === -1) {
        const col = JSON.parse(JSON.stringify(["currentColor","white"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = colors;
            }
            return item;
          });
        } else {
          _col[0] = colors;
        }
        setColorList(_col);
        setColorsProps(_col);
      }

      // 如果colors 是数组
      if (colors instanceof Array && "chart-pie".indexOf("-colored") === -1) {
        // 获取数组中的对象
        const colorList1 = JSON.parse(JSON.stringify(["currentColor","white"]));
        colors.forEach((col) => {
          // 获取到修改的元素
          const findColorIndex = colorList1.findIndex(
            (v) => v === col.targetColor
          );
          if (findColorIndex >= 0) {
            colorList1[findColorIndex] = col.reviseColor;
          }
        });
        setColorList([...colorList1]);
        setColorsProps([...colorList1]);
      }

      if (
        color &&
        typeof color === "string" &&
        "chart-pie".indexOf("-colored") === -1
      ) {
        const col = JSON.parse(JSON.stringify(["currentColor","white"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = color;
            }
            return item;
          });
        } else {
          _col[0] = color;
        }
        setColorList(_col);
        setColorsProps(_col);
      }
    }, [JSON.stringify(colors), color, defaultColor]);

    const handleMouseEnter = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseEnter && onMouseEnter(event);
        if (!hover || hover.length === 0) return;
        if (colors instanceof Array && "chart-pie".indexOf("-colored") === -1) {
          if (hover instanceof Array && "chart-pie".indexOf("-colored") === -1) {
            // 获取数组中的对象
            const colorList1 = JSON.parse(JSON.stringify(colorList));
            hover.forEach((col) => {
              // 获取到修改的元素
              const findColorIndex = colorList1.findIndex(
                (v) => v === col.targetColor
              );
              if (findColorIndex >= 0) {
                colorList1[findColorIndex] = col.reviseColor;
              }
            });
            setColorList([...colorList1]);
          }
        }

        if (typeof colors === "string" && "chart-pie".indexOf("-colored") === -1) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === colors);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === colors) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          color &&
          typeof color === "string" &&
          "chart-pie".indexOf("-colored") === -1
        ) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === color);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === color) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          !color &&
          colors.length === 0 &&
          typeof hover === "string" &&
          "chart-pie".indexOf("-colored") === -1
        ) {
          const col = JSON.parse(JSON.stringify(colorList));
          const find = col.find((item) => item === "currentColor");
          let _col = col;
          if (find) {
            _col = col.map((item) => {
              if (item === "currentColor") {
                item = hover;
              }
              return item;
            });
          } else {
            _col[0] = hover;
          }
          setColorList(_col);
        }

        if (
          !color &&
          colors.length === 0 &&
          hover instanceof Array &&
          "chart-pie".indexOf("-colored") === -1
        ) {
          // 获取数组中的对象
          const colorList1 = JSON.parse(JSON.stringify(colorList));
          hover.forEach((col) => {
            // 获取到修改的元素
            const findColorIndex = colorList1.findIndex(
              (v) => v === col.targetColor
            );
            if (findColorIndex >= 0) {
              colorList1[findColorIndex] = col.reviseColor;
            }
          });
          setColorList([...colorList1]);
        }
      },
      [colorList, defaultColor, colors, color, hover]
    );

    const handleMouseLeave = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseLeave && onMouseLeave(event);
        if (!hover || hover.length === 0) return;
        let _defaultColor = JSON.parse(JSON.stringify(defaultColor));
        if (colorsProps) {
          _defaultColor = JSON.parse(JSON.stringify(colorsProps));
        }
        setColorList(_defaultColor);
      },
      [colorsProps, defaultColor, hover]
    );

    return <svg id="chart-pie"  style={sizeStyle}  className={cls}  ref={ref}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...resultProps}  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="none"><g clipPath="url(#clip0_19498_1151)"><path d="M7.33317 2.03658C4.3332 2.36821 1.99984 4.91159 1.99984 7.99996C1.99984 11.3137 4.68613 14 7.99984 14C11.0882 14 13.6316 11.6666 13.9632 8.66663H7.33317V2.03658ZM8.6665 2.03658V7.33329H13.9632C13.6558 4.55221 11.4476 2.34401 8.6665 2.03658ZM0.666504 7.99996C0.666504 3.94987 3.94975 0.666626 7.99984 0.666626C12.0499 0.666626 15.3332 3.94987 15.3332 7.99996C15.3332 12.05 12.0499 15.3333 7.99984 15.3333C3.94975 15.3333 0.666504 12.05 0.666504 7.99996Z" fill={colorList[0]} fillRule="evenodd" clipRule="evenodd" fillOpacity="0.9"/></g><defs><clipPath id="clip0_19498_1151"><rect width="16" height="16" fill={colorList[1]}/></clipPath></defs></g></svg>;
  }
);

ChartPieIcon.displayName = "ChartPieIcon";

export default ChartPieIcon;
