import _Radio from './Radio';

import './style/index.js';

export type { RadioProps } from './Radio';
export type { RadioGroupProps } from './RadioGroup';
export * from './type';

export const Radio = _Radio;
export const RadioGroup = _Radio.Group;

export default Radio;
