import React, {
  forwardRef,
  LegacyRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IconProps, useApiProps } from "../icon";
// import "../style/css";

const MinusCircleIcon = forwardRef<SVGSVGElement, IconProps>(
  (props: IconProps, ref: LegacyRef<SVGSVGElement>) => {
    const {
      colors = [],
      className,
      id = "",
      hover = "",
      size,
      disabled = false,
      style,
      color,
      onMouseEnter,
      onMouseLeave,
      ...resultProps
    } = props;
    //@ts-ignore
    const { sizeStyle, cls } = useApiProps({
      className,
      initColor: ["currentColor","white"],
      initName: "minus-circle",
      colors,
      id,
      hover,
      size,
      style,
      disabled,
      color,
    });
    // 默认的color 列表
    const [colorList, setColorList] = useState(["currentColor","white"]);
    const [colorsProps, setColorsProps] = useState(["currentColor","white"]);
    const [defaultColor, _] = useState(["currentColor","white"]);

    useEffect(() => {
      if (typeof colors === "string" && "minus-circle".indexOf("-colored") === -1) {
        const col = JSON.parse(JSON.stringify(["currentColor","white"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = colors;
            }
            return item;
          });
        } else {
          _col[0] = colors;
        }
        setColorList(_col);
        setColorsProps(_col);
      }

      // 如果colors 是数组
      if (colors instanceof Array && "minus-circle".indexOf("-colored") === -1) {
        // 获取数组中的对象
        const colorList1 = JSON.parse(JSON.stringify(["currentColor","white"]));
        colors.forEach((col) => {
          // 获取到修改的元素
          const findColorIndex = colorList1.findIndex(
            (v) => v === col.targetColor
          );
          if (findColorIndex >= 0) {
            colorList1[findColorIndex] = col.reviseColor;
          }
        });
        setColorList([...colorList1]);
        setColorsProps([...colorList1]);
      }

      if (
        color &&
        typeof color === "string" &&
        "minus-circle".indexOf("-colored") === -1
      ) {
        const col = JSON.parse(JSON.stringify(["currentColor","white"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = color;
            }
            return item;
          });
        } else {
          _col[0] = color;
        }
        setColorList(_col);
        setColorsProps(_col);
      }
    }, [JSON.stringify(colors), color, defaultColor]);

    const handleMouseEnter = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseEnter && onMouseEnter(event);
        if (!hover || hover.length === 0) return;
        if (colors instanceof Array && "minus-circle".indexOf("-colored") === -1) {
          if (hover instanceof Array && "minus-circle".indexOf("-colored") === -1) {
            // 获取数组中的对象
            const colorList1 = JSON.parse(JSON.stringify(colorList));
            hover.forEach((col) => {
              // 获取到修改的元素
              const findColorIndex = colorList1.findIndex(
                (v) => v === col.targetColor
              );
              if (findColorIndex >= 0) {
                colorList1[findColorIndex] = col.reviseColor;
              }
            });
            setColorList([...colorList1]);
          }
        }

        if (typeof colors === "string" && "minus-circle".indexOf("-colored") === -1) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === colors);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === colors) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          color &&
          typeof color === "string" &&
          "minus-circle".indexOf("-colored") === -1
        ) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === color);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === color) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          !color &&
          colors.length === 0 &&
          typeof hover === "string" &&
          "minus-circle".indexOf("-colored") === -1
        ) {
          const col = JSON.parse(JSON.stringify(colorList));
          const find = col.find((item) => item === "currentColor");
          let _col = col;
          if (find) {
            _col = col.map((item) => {
              if (item === "currentColor") {
                item = hover;
              }
              return item;
            });
          } else {
            _col[0] = hover;
          }
          setColorList(_col);
        }

        if (
          !color &&
          colors.length === 0 &&
          hover instanceof Array &&
          "minus-circle".indexOf("-colored") === -1
        ) {
          // 获取数组中的对象
          const colorList1 = JSON.parse(JSON.stringify(colorList));
          hover.forEach((col) => {
            // 获取到修改的元素
            const findColorIndex = colorList1.findIndex(
              (v) => v === col.targetColor
            );
            if (findColorIndex >= 0) {
              colorList1[findColorIndex] = col.reviseColor;
            }
          });
          setColorList([...colorList1]);
        }
      },
      [colorList, defaultColor, colors, color, hover]
    );

    const handleMouseLeave = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseLeave && onMouseLeave(event);
        if (!hover || hover.length === 0) return;
        let _defaultColor = JSON.parse(JSON.stringify(defaultColor));
        if (colorsProps) {
          _defaultColor = JSON.parse(JSON.stringify(colorsProps));
        }
        setColorList(_defaultColor);
      },
      [colorsProps, defaultColor, hover]
    );

    return <svg id="minus-circle"  style={sizeStyle}  className={cls}  ref={ref}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...resultProps}  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="none"><g clipPath="url(#clip0_19498_564)"><path d="M8.00016 14C11.3139 14 14.0002 11.3137 14.0002 7.99998C14.0002 4.68627 11.3139 1.99998 8.00016 1.99998C4.68646 1.99998 2.00016 4.68627 2.00016 7.99998C2.00016 11.3137 4.68645 14 8.00016 14ZM15.3335 7.99998C15.3335 12.0501 12.0503 15.3333 8.00016 15.3333C3.95008 15.3333 0.66683 12.0501 0.666831 7.99998C0.666831 3.94989 3.95008 0.666646 8.00016 0.666646C12.0503 0.666647 15.3335 3.94989 15.3335 7.99998ZM11.3335 8.66665L4.66683 8.66665L4.66683 7.33331L11.3335 7.33331L11.3335 8.66665Z" fill={colorList[0]} fillRule="evenodd" clipRule="evenodd" fillOpacity="0.9"/></g><defs><clipPath id="clip0_19498_564"><rect width="16" height="16" fill={colorList[1]}/></clipPath></defs></g></svg>;
  }
);

MinusCircleIcon.displayName = "MinusCircleIcon";

export default MinusCircleIcon;
