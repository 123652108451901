// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M2 2h20v20H2V2zm2 2v16h16V4H4zm9 2.5V11h4.5v2H13v4.5h-2V13H6.5v-2H11V6.5h2z"}}]};

const AddRectangleIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'add-rectangle',
    ref,
    icon: element,
  },
));

AddRectangleIcon.displayName = 'AddRectangleIcon';

export default AddRectangleIcon;
