// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M3 1h12.41L21 6.59V11h-2V9h-6V3H5v18h16v2H3V1zm12 2.41V7h3.59L15 3.41zM6 12h3.71c.71 0 1.29.58 1.29 1.29v2.42c0 .71-.58 1.29-1.29 1.29H8v3H6v-8zm2 3h1v-1H8v1zm3.5-3h3.71c.71 0 1.29.58 1.29 1.29v5.42c0 .71-.58 1.29-1.29 1.29H11.5v-8zm2 2v4h1v-4h-1zm3.5-.71c0-.71.58-1.29 1.29-1.29h3.38v2H19v1h2.67v2H19v3h-2v-6.71z"}}]};

const FilePdfIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'file-pdf',
    ref,
    icon: element,
  },
));

FilePdfIcon.displayName = 'FilePdfIcon';

export default FilePdfIcon;
